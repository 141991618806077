export const CommoditySlugs = {
  kreditHp: "kredit-hp",
  kreditKamera: "kredit-kamera",
  kreditTv: "kredit-tv",
  kreditLaptop: "kredit-laptop",
  kreditElektronik: "kredit-elektronik",
  kreditFurnitur: "kredit-furnitur",
  kreditGadget: "kredit-gadget",
  kreditAksesorisKendaraan: "kredit-aksesoris-kendaraan",
  homeImprovement: "kredit-bahan-bangunan-dan-perbaikan-rumah"
};

export const ProteksiSlugs = {
  proteksi: "proteksi"
};

export const ConsumenSlugs = {
  caraPembayaran: "cara-pembayaran"
};

export const CompanySlugs = {
  acaraPerusahaan: "acara-perusahaan",
  siaranPers: "siaran-pers",
  penghargaanKami: "penghargaan-kami",
  tentangPerusahaan: "tentang-perusahaan"
};
